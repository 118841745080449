<template>
  <CsTableCard
    :loading="loading"
    card-header="Elenco Opere"
    :rows="rows"
    :columns="columns"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :show-actions="canDetails || canIspezioni || canDelete"
        :props="props"
      >
        <!--            <cs-table-action-item-->
        <!--              value="Bim"-->
        <!--              icon="BoxIcon"-->
        <!--              :row="props.row"-->
        <!--              @click="bimRow"-->
        <!--            />-->
        <cs-table-action-item-details
          :show="canDetails"
          :row="props.row"
          @click="detailsRow"
        />
        <cs-table-action-item
          :show="canIspezioni"
          value="Ispezioni"
          icon="FileTextIcon"
          :row="props.row"
          @click="ispezioniRow"
        />
        <cs-table-action-item-delete
          :show="canDelete"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Nome',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Numero',
          field: 'numero',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Codice IOP',
          field: 'codiceIop',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canDetails: this.$grants.OPERE_VISUALIZZA_DETTAGLIO.can,
      canDelete: this.$grants.OPERE_ELIMINA.can,
      canIspezioni: this.$grants.ISPEZIONI_VISUALIZZA.can,
    }
  },
  created() {
    this.$remote.opere.all()
      .then(result => { this.rows = result.data })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    detailsRow(params) {
      this.$routing.OPERE_DETTAGLIO.push({ id: params.id })
    },
    ispezioniRow(params) {
      this.$routing.ISPEZIONI_OPERA.push({ operaId: params.id })
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.opere.delete(params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    // bimRow() {
    //   this.$router.push({ name: 'bim' })
    // },
  },
}
</script>
